
@use "@angular/material" as mat;


@include mat.core();

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

//@import './default_theme';

@import './variable';

@import './custom';

@import './icons/themify-icons/themify-icons.css';
@import './icons/font-awesome/css/font-awesome.css';
@import './icons/flag-icon-css/flag-icon.min.css';
@import './spinner';
@import './app';
@import './header';
@import './widgets';

@import './pages';
@import './sidebar';
@import './responsive';
@import './rtl';

@include mat.all-component-themes($admin-portal-theme-light);

body.admin-portal-dark-theme {
  @include mat.all-component-colors($admin-portal-theme-dark);
}



