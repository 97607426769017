@use "@angular/material" as mat;

@import './default_theme';

// Light theme
$theme-light-primary: mat.m2-get-color-from-palette($theme-primary, 'default');
$theme-light-warning: mat.m2-get-color-from-palette($theme-warn, 'default');
$theme-light-light-danger: #f9e7eb;
$theme-light-background: map-get(map-get($admin-portal-theme-light, 'background'), 'background');
$theme-light-foreground: map-get(map-get($admin-portal-theme-light, 'background'), 'card');
$theme-light-info: #beeaff;
$theme-light-text: map-get(map-get($admin-portal-theme-light, 'foreground'), 'text');
$theme-light-text-contrast-primary: mat.m2-get-color-from-palette($theme-primary, 'default-contrast');
$theme-light-text-foreground-secondary: map-get(map-get($admin-portal-theme-light, 'foreground'), 'secondary-text');
$theme-light-text-muted: rgb(0, 0, 0, 0.45);

// Dark theme
$theme-dark-primary: mat.m2-get-color-from-palette($theme-primary, 'default');
$theme-dark-warning: mat.m2-get-color-from-palette($theme-warn, 'default');
$theme-dark-light-danger: #d13d5e;
$theme-dark-background: map-get(map-get($admin-portal-theme-dark, 'background'), 'background');
$theme-dark-foreground: map-get(map-get($admin-portal-theme-dark, 'background'), 'card');
$theme-dark-info: #455A64;
$theme-dark-text: map-get(map-get($admin-portal-theme-dark, 'foreground'), 'text');
$theme-dark-text-contrast-primary: mat.m2-get-color-from-palette($theme-primary, 'default-contrast');
$theme-dark-text-foreground-secondary: map-get(map-get($admin-portal-theme-dark, 'foreground'), 'secondary-text');
$theme-dark-text-muted: rgb(255,  255, 255, 0.45);

$theme-light-and-dark-info-dark: #7460ee;
$headingtext: #455a64;
$bodytext: #67757c;
$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;


$font-16: 16px;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #24c758;
$inverse: #2f3d4a;
$light: #f2f4f8;
$extra-light: #ebf3f5;

/*Light colors*/
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #295bac;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

/*$page-wrapper-boxed-width: 1300px*/
$page-wrapper-boxed-width: 100%;

$mat-card-margin: 15px;

$sidenav-width: 13rem;


.bg-primary,
.topbar {
  background: mat.m2-get-color-from-palette($theme-primary, 500) !important;
}

.topbar .mat-mdc-icon-button,
.topbar .mat-mdc-button {
  color: $white !important;
}

.horizontal-navbar .parentBox.pactive > a,
.horizontal-navbar .parentBox.pactive > a:hover,
.horizontal-navbar .parentBox.pactive:hover > a {
  background-color: mat.m2-get-color-from-palette($theme-accent, 500) !important;
}

.activeMenu {
  background-color: mat.m2-get-color-from-palette($theme-accent, 500) !important;
}


// outline button border
.mat-mdc-outlined-button {
  &.mat-primary{
    border-color: mat.m2-get-color-from-palette($theme-primary, 500) !important;
  }
  &.mat-accent{
    border-color: mat.m2-get-color-from-palette($theme-accent, 500) !important;
  }
  &.mat-warn{
    border-color: mat.m2-get-color-from-palette($theme-warn, 500) !important;
  }
}
